<template>
  <!-- jput -->
  <div class="box-card w-full responsiveTable-card" v-bind:class="{'mobile-view':isMobileScreen}">
    <div 
      class="flex flex-row justify-end p-1 laptop:pb-2 desktop:pb-4 pr-2" 
      v-if="isAdmin || isCustomerAdmin || isSurveyorAdmin"
    >
      <el-button
        size="small"
        plain
        @click="handleAddClick"
      >
        {{ $t("us.addUser") }}</el-button
      >
    </div>
    <div class="responsiveTable-table">
      <el-table
        :cell-style="getCellStyle"
        v-loading="Jputloading"
        :data="tableData"
        :header-cell-style="{
          height: screenType.isMobileScreen? '40px':'55px',
          background: '#fff',
          'border-bottom': '2px solid #D6D9E1',
        }"
        table-layout="auto"
        height="100%"
      >
        <!-- <el-table-column width="20"> </el-table-column> -->
        <el-table-column
          width="100"
          align="left"
          type="index"
          :label="$t('table.no')"
        >
        </el-table-column>
        <el-table-column
          width="180"
          align="left"
          prop="nickName"
          :label="$t('table.Name')"
        >
        </el-table-column>
        <el-table-column
          width="180"
          align="left"
          prop="email"
          :label="$t('table.EmailAddress')"
        >
        </el-table-column>
        <el-table-column
          width="180"
          align="left"
          prop="roleLabel"
          :label="$t('table.Role')"
        >
        </el-table-column>
        <el-table-column
          width="300"
          align="left"
          prop="status"
          :label="$t('table.Status')"
        >
          <template slot-scope="scope">
            <el-tag
              class="Statustag"
              :type="
                scope.row.status == '0'
                  ? 'success'
                  : scope.row.status == '1'
                  ? 'danger'
                  : 'pink'
              "
              disable-transitions
              :color="
                scope.row.status == '0'
                  ? '#CEF9CD'
                  : scope.row.status == '1'
                  ? '#FFE6E1'
                  : '#FFEFD9'
              "
            >
              {{
                scope.row.status == "0"
                  ? $t("state.Active")
                  : scope.row.status == "1"
                  ? $t("state.Deactivated")
                  : $t("state.PendingVerification")
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope">
            <div
              v-if="isAdmin || isCustomerAdmin || isSurveyorAdmin || islogistics"
            >
              <el-dropdown
                v-if="scope.row.status == 0 || scope.row.status == 1"
                trigger="click"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="handleEditUserClick(scope.row, scope.$index)"
                    >Edit User</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="handleDeactiveClick(scope.row, scope.$index)"
                  >
                    {{
                      scope.row.status == 0 ? "Deactivate User" : "Activate User"
                    }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown v-else trigger="click">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="handleResendRequestClick(scope.row)"
                    >Resend Request</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="handleDeleteUserClick(scope.row)"
                    >Delete User</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50"> </el-table-column>
      </el-table>
    </div>
    <!-- 新增弹窗 -->
    <el-dialog
      width="360px"
      :title="$t('jm.AddUser')"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="form"
        size="small"
        label-position="top"
        :model="form"
        :rules="rules"
      >
        <el-form-item :label="$t('jm.Name')" prop="nickName">
          <el-input
            size="small"
            v-model="form.nickName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('jm.EmailAddress')" prop="email">
          <el-input size="small" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item
          prop="role"
          :label="$t('jm.Role')"
          v-if="isAdmin || islogistics"
        >
          <el-select
              placeholder
            size="small"
            v-model="form.role"
            style="width: 100%"
          >
            <el-option
              v-for="item in Rolelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option
          ></el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          :loading="btnloading"
          size="small"
          type="primary"
          @click="handleSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 编辑用户弹窗 -->
    <el-dialog
      width="360px"
      :title="$t('jm.editUser')"
      :visible.sync="editUserDialog.open"
    >
      <el-form
        ref="editUserForm"
        size="small"
        label-position="top"
        :model="editUserForm"
        :rules="rules"
      >
        <el-form-item :label="$t('jm.Name')" prop="nickName">
          <el-input
            size="small"
            v-model="editUserForm.nickName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('jm.EmailAddress')" prop="email">
          <el-input
            size="small"
            v-model="editUserForm.email"
            :disabled="userInfo.userType == 1"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="role"
          :label="$t('jm.Role')"
          v-if="isAdmin || islogistics"
        >
          <el-select
            placeholder
            size="small"
            v-model="editUserForm.role"
            style="width: 100%"
          >
            <el-option
              v-for="item in Rolelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option
          ></el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          :loading="btnloading"
          size="small"
          type="primary"
          @click="handleSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCellStyle } from "@/utils/getCellStyle";
import {
  addUser,
  editUser,
  freezeUser,
  getUserList,
  unFreezeUser,
  revalidateEmail,
  deleteUser,
} from "@/services/user";
import { mapState } from "vuex";
const juptRoleMap = {
  1: "Terminal Logistics",
  2: "Terminal Control Room",
  3: "Terminal Team Leader",
  10: "Terminal Pilot" ,
  11: "Terminal Loading Master",
  12: "Terminal Head Of Pilot",
};
export default {
  data() {
    return {
      dialogFormVisible: false,
      tableData: [],
      form: {
        nickName: "",
        email: "",
        role: "",
      },
      rules: {
        nickName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        email: [{ required: true, message: "Please Input", trigger: "blur" }],
        role: [{ required: true, message: "Please Input", trigger: "blur" }],
      },
      btnloading: false,
      Jputloading: true,
      Rolelist: [
        { value: "1", label: "Terminal Logistics" },
        { value: "2", label: "Terminal Control Room" },
        { value: "3", label: "Terminal Team Leader" },
        { value: "10", label: "Terminal Pilot" },
        { value: "11", label: "Terminal Loading Master" },
        { value: "12", label: "Terminal Head Of Pilot" },
      ],
      editUserForm: {
        nickName: "",
        email: "",
        role: "",
      },
      editUserDialog: {
        open: false,
        userId: null,
        index: null,
      },
      isEdit: false,
    };
  },
  watch: {
    dialogFormVisible(val) {
      this.$nextTick(() => {
        if (val) {
          this.form.nickName = "";
          this.form.nickName = "";
          this.form.email = null;
          this.form.role = "";
        }
      });
    },
  },
  created() {
    this.getList();
    if (this.isSurveyorAdmin || this.isCustomerAdmin) {
      this.rules = {
        nickName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        email: [{ required: true, message: "Please Input", trigger: "blur" }],
      };
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isCustomerAdmin() {
      return this?.userInfo.userType == 2 && this.userInfo.role == 4;
    },
    isSurveyorAdmin() {
      return this?.userInfo.userType == 3 && this.userInfo.role == 6;
    },
    isAdmin() {
      return this.userInfo.userType == 0;
    },
    isJuputLeader() {
      return this.userInfo.userType == 1 && this.userInfo.role == 3;
    },
    islogistics() {
      return this.userInfo.userType == 1 && this.userInfo.role == 1;
    },
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  methods: {
    getCellStyle,
    handleAddClick() {
      this.dialogFormVisible = true;
      this.isEdit = false;
    },
    getList() {
      getUserList().then((res) => {
        // res.data = new Array(40).fill(res.data[0]);
        let data = res.data.map((item) => {
          if (this.isAdmin || this.userInfo.userType == 1) {
            return {
              ...item,
              roleLabel: juptRoleMap[item.role],
            };
          }
          return {
            ...item,
            roleLabel:
              this?.userInfo.userType == 2 ? "Customer User" : "SurveyorUser",
          };
        });
        this.tableData = data;
      }).finally(() => {
        this.Jputloading = false;
      });
    },
    async handleSaveClick() {
      let form;
      if (this.isEdit) {
        form = this.$refs.editUserForm;
      } else {
        form = this.$refs.form;
      }
      form.validate((res) => {
        if (res) {
          this.btnloading = true;
          let data = {
            companyId: this.userInfo.userId,
          };
          if (this.isEdit) {
            data = {
              ...data,
              ...this.editUserForm,
            };
          } else {
            data = {
              ...data,
              ...this.form,
            };
          }
          if (this.isCustomerAdmin) {
            data.userType = "2";
            data.role = "5";
          }
          if (this.isSurveyorAdmin) {
            data.userType = "3";
            data.role = "7";
          }
          if (this.isAdmin) {
            data.userType = "1";
          }
          if (this.islogistics) {
            data.userType = "1";
          }
          let request;
          if (this.isEdit) {
            data["userId"] = this.editUserDialog.userId;
            request = editUser(data);
          } else {
            request = addUser(data);
          }
          request
            .then(() => {
              this.$message.success(this.$t("status.success"));
              this.getList();
              this.$emit("list");
              this.editUserDialog.open = false;
              this.dialogFormVisible = false;
            })
            .finally(() => (this.btnloading = false));
        }
      });
    },
    handleEditUserClick(item, index) {
      this.editUserDialog = {
        open: true,
        index,
        userId: item["userId"],
      };
      this.editUserForm.nickName = item.nickName;
      this.editUserForm.email = item.email;
      if (this.isAdmin || this.islogistics) {
        this.editUserForm.role = item.role;
      }
      this.isEdit = true;
    },
    handleDeactiveClick(item) {
      this.$confirm(
        `Confirm ${item.status == 0 ? "Deactivate User" : "Activate User"}?`,
        "System Tips",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let request;
        if (item.status == 0) {
          request = freezeUser({
            userId: item.userId,
          });
        } else {
          request = unFreezeUser({
            userId: item.userId,
          });
        }
        request.then(() => {
          this.getList();
          this.$message.success(this.$t("status.success"));
          this.$emit("list");
        });
      });
    },
    handleDeleteUserClick(row) {
      deleteUser(row.userId).then(() => {
        this.$message.success(this.$t("status.success"));
        this.getList();
      })
    },
    handleResendRequestClick(row) {
      revalidateEmail(row.userId).then(() => {
        this.$message.success(this.$t("status.success"));
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-table {
  font-size: 12px;
}
.Statustag {
  border-radius: 10px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.el-button--default {
  color: #fff;
  background-color: #008CDB;
}
.el-button--default.is-plain:hover {
  color: #fff;
  background: #008CDB;
  border-color: #008CDB;
}
.el-button--default:focus {
  color: #fff !important;
  background: #008CDB !important;
  border-color: #008CDB;
}
.el-icon-more {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  color: #82889c;
}
::v-deep .el-form-item--small .el-form-item__label {
  line-height: 28px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 12px;
}
::v-deep .el-table__header {
  width: 100%;
}

.responsiveTable-card {
  position: absolute;
  width: 100%;
}

.responsiveTable-table {
  height: calc(100vh - 200px);
  overflow-y: auto;
  .el-table {
    font-size: 12px;
    div{
      word-break:keep-all;
    }
  }
}

::v-deep .el-table__row > .el-table__cell > .cell {
  font-size: 11px;
}
::v-deep.el-table th.el-table__cell > .cell {
  font-size: 11px;
  // color: #1D1B20 !important;
  // padding-left: 25px;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .el-table th.el-table__cell.is-leaf{
  padding: 0px;
}
::v-deep .bargename-header .cell{
  display: flex;
  align-items: center;
}
::v-deep .bargename-header .caret-wrapper{
  margin-left: 15%;
  margin-top: -20%;
}
.mobile-view{
    ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 10px;
    line-height:9px;
    color: #1D1B20 !important;
  }
  ::v-deep .el-table__row > .el-table__cell{
    padding:6px 0!important;
  }
  ::v-deep .el-table__row > .el-table__cell > .cell {
    font-size: 10px;
    line-height:8px; 
  }
  .responsiveTable-table{
    ::v-deep .el-table__cell > .cell{
      padding-left: 3px;
      padding-right: 2px;
      b{
        word-break:keep-all;
      }
    }
    ::v-deep th.el-table__cell > .cell{
      padding-left: 3px;
      padding-right: 1px;
      b{
        word-break:keep-all;
      }
    }
    .el-select--mini{
      ::v-deep .el-input__inner{
        font-size: 10px;
        padding-right:5px!important;
        padding-left:5px!important;
      }
    }
    .dataPicker{
      ::v-deep .el-input__inner{
        font-size: 10px;
        padding-right:5px!important;
        padding-left:25px!important;
      }
    }
  }
  ::v-deep .bargename-header .caret-wrapper{
    margin-left: 10%;
    margin-top: -10%;
  }
  ::v-deep .el-table  .el-table__cell{
    padding: 6px 0px !important;
  }
}

::v-deep .el-table__row > .el-table__cell > .cell {
  color: #1D1B20 !important;
}
::v-deep .el-input--small .el-input__inner, .refreshBtn {
  border: 1px solid #82889C;
  color: #82889C;
}
::v-deep .el-select .el-input .el-select__caret {
  color: #82889C;
}
::v-deep .el-table .sort-caret.descending {
  border-top-color: #1D1B20;
}
::v-deep .el-table .sort-caret.ascending {
  border-bottom-color: #1D1B20;
}
::v-deep .el-table__row td {
  border-bottom: 1px solid #D5DDED;
}
</style>
